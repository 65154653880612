import clientApi from "../../api/client.api";
import filterDefault from "../../helpers/filter";

export const state = {
  categories: [],
  client: {},
  filter: {
    ...filterDefault,
    ordering: "name",
  },
};

export const getters = {
  categories(state) {
    return state.categories;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_CLIENTS(state, categories) {
    state.categories = categories;
  },

  ADD_CLIENT(state, client) {
    state.categories.unshift(client);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_CLIENT(state, id) {
    state.categories.splice(
      state.categories.findIndex((client) => client.id === id),
      1
    );
  },

  SET_CLIENT(state, client) {
    state.client = client;
  },

  CHANGE_CLIENT(state, { id, client }) {
    state.categories[
      state.categories.findIndex((client) => client.id === id)
    ] = client;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetchClients");
  },

  async fetchClients({ commit, state }) {
    try {
      const { data } = await clientApi.fetch(state.filter);

      commit("SET_CLIENTS", data.data.data);

      return data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await clientApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_CLIENT", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await clientApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_CLIENT", id);

    return { status: true };
  },

  async detail(context, id) {
    const data = await clientApi.detail(id);

    return data.data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await clientApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_CLIENT", { id, client: data.data });

    return { status: true };
  },
};

import portfolioApi from "../../api/portfolio.api";
import filterDefault from "../../helpers/filter";

export const state = {
  categories: [],
  portfolio: {},
  filter: {
    ...filterDefault,
    ordering: "name",
  },
};

export const getters = {
  categories(state) {
    return state.categories;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_PORTFOLIOS(state, categories) {
    state.categories = categories;
  },

  ADD_PORTFOLIO(state, portfolio) {
    state.categories.unshift(portfolio);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_PORTFOLIO(state, id) {
    state.categories.splice(
      state.categories.findIndex((portfolio) => portfolio.id === id),
      1
    );
  },

  SET_PORTFOLIO(state, portfolio) {
    state.portfolio = portfolio;
  },

  CHANGE_PORTFOLIO(state, { id, portfolio }) {
    state.categories[
      state.categories.findIndex((portfolio) => portfolio.id === id)
    ] = portfolio;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetchPortfolios");
  },

  async fetchPortfolios({ commit, state }) {
    try {
      const { data } = await portfolioApi.fetch(state.filter);

      commit("SET_PORTFOLIOS", data.data.data);

      return data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await portfolioApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_PORTFOLIO", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await portfolioApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_PORTFOLIO", id);

    return { status: true };
  },

  async detail(context, id) {
    const data = await portfolioApi.detail(id);

    return data.data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await portfolioApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PORTFOLIO", { id, portfolio: data.data });

    return { status: true };
  },
};
